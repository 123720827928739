// ================================================================================================
//  File Name: colors.scss
//  Description: Common color file to include color paletter and colors file, its qiore good to
//  have all color classes in a separate file as it's quite heavy.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core
// ------------------------------

// Import variables and mixins
@import 'bootstrap/scss/mixins/gradients';
@import 'core/mixins/hex2rgb';

//box shadow for alert's heading
@import 'core/mixins/alert';

// Color system, always load last
// ------------------------------
@import 'core/colors/palette';
@import 'core/colors/palette-gradient.scss';

.hover-alpha:hover {
  opacity: 0.7
}
